<template>
  <div class="home">
    <div class="flex cloumn-left">
      <!-- 天气 -->
      <div id="air-quality">
        <div class="flex-item">
          <span class='today_temp_title'>今日温度</span>
          <span class='today_temp'>{{weather_info.temp}}℃</span>
        </div>
        <div class="flex-item" id="weather">
          <span class='today_pm25'>PM2.5值: {{weather_info.aqi}} {{weather_info.qlty}}</span>
          <span class='today_weather'>
            {{weather_info.cond_txt}} &nbsp;
            {{weather_info.wind_dir}}{{weather_info.wind_sc}}级
          </span>
        </div>
        <div class="flex-item">
          <span class='date'>{{now}}</span>
        </div>
      </div>
      <div class="column-left-bottom">
        <div class="column-left-stores">
          <div id="traffic-realtime">
            <p class="title"><a href="/#/renliufenxi">人流预警</a></p>
            <Traffic/>
          </div>
          <div id="shangpujingyinfenxi">
            <div id="hangyezhanbi">
              <p class="title"><a href="/#/shangpuguanli">商家数量共<span>{{storeCount}}</span>家</a></p>
              <HangYeZhanBi @updateStoreCount="changeStoreCount" />
            </div>
            <div>
              <p id='jingying-title' class="title" style="width: 5px;float: left;padding-left:0;padding-top:10px;"><a href="/#/shangpuguanli">当月经营情况</a></p>
              <DianPuXiaoFeiPangHang/>
            </div>
          </div>
        </div>
        <div class="column-left-turnover">
          <div id="traffic-stat">
            <p class="title">
              <a href="/#/renliufenxi">当前街区总人数<span class="current-traffice">{{traffice_info.head_count.data_current_num}}</span>人</a>
            </p>
            <p class="title">
              <a href="/#/renliufenxi">商圈每日人流数量</a>
            </p>
            <DailyTraffic/>
          </div>

          <div id="scenes">
            <p class="title"><a href="/#/jingdianguanli">各景点人流数</a></p>
            <Scenes/>
          </div>
        </div>
      </div>
    </div>
    <div class="cloumn-middle">
      <div id="maps" class="transparentBackground">
      </div>
      <div id="complaint-list">
        <p class="title">投诉建议</p>
        <table>
          <tr v-for="(opinion, index) in this.opinion_list" :key="index">
            <td>{{opinion.content}}</td>
            <td width="30%"><span class="score">{{opinion.date}}</span></td>
          </tr>
        </table>              
      </div>
    </div>
    <div class="cloumn-right">
       <!-- 人流 -->
      <div id="traffic">
        <div id="member-layers">
          <p class="title"><a href="/#/shangpuguanli">会员累计数量</a></p>
          <Members/>
        </div>
        <div id="members">
          <p class="title"><a href="/#/shangpuguanli">会员人数</a></p>
          <ul>
            <li>当天注册: <span class="member-count">{{member_info.today_register}}人</span></li>
            <li>昨日注册: <span class="member-count">{{mp_member_info.yesterdayData.new_user || member_info.yesterday_register}}人</span></li>
            <li>当日会员消费额: <span class="member-count">{{member_info.today_consume}}元</span></li>
            <li>当日会员消费比率: <span class="member-count">{{member_info.consume_ratio}}%</span></li>
            <li>当日活跃会员: <span class="member-count">{{member_info.today_active}}人</span></li>
            <li>当日活跃会员比率: <span class="member-count">{{member_info.active_ratio}}%</span></li>
            <li>昨日活跃会员: <span class="member-count">{{member_info.yesterday_active}}人</span></li>
            <li>昨日会员消费额: <span class="member-count">{{member_info.yesterday_consume}}元</span></li>
          </ul>
        </div>
      </div>
      <div class="column-left-bottom">
        <div class="column-left-stores">
          <div id="realtime-orders">
            <p id="orders-title" class="title"><a href="/#/shangpuguanli">当天实时在线订单量</a></p>
            <ShangChenWuLiu/>
          </div>
          <div id="accident">
            <p class="title"><a href="/#/">商圈事故进度统计分析</a></p>
            <Accident/>
          </div>
        </div>
        <div class="column-left-turnover">
          <div id="coupons">
            <p id="coupon-title" class='title'><a href="/#/shangpuguanli">每日优惠券领用情况</a></p>
            <Coupon/>
          </div>
          <div id="complaints">
            <p class="title">投诉建议处理情况</p>
            <Complaint/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HangYeZhanBi from "@/components/Overview/HangYeZhanBi";
import DianPuXiaoFeiPangHang from "@/components/Overview/DianPuXiaoFeiPangHang";
import ShangChenWuLiu from "@/components/Overview/ShangChenWuLiu";
import Traffic from "@/components/Overview/Traffic";
import DailyTraffic from "@/components/Overview/DailyTraffic";
import Accident from "@/components/Overview/Accident";
import Coupon from "@/components/Overview/Coupon";
import Complaint from "@/components/Overview/Complaint";
import Members from "@/components/Overview/Members";
import Scenes from "@/components/Overview/Scenes";

import { getWeather, getHeatingPower, getMemberInfo, getUnhandledComplaintList, getMPMemberInfo } from "@/api/api.js";

let moment = require("moment") // 引入

export default {
  name: "Overview",
  components: {
    HangYeZhanBi,
    DianPuXiaoFeiPangHang,
    ShangChenWuLiu,
    Traffic,
    DailyTraffic,
    Accident,
    Coupon,
    Complaint,
    Members,
    Scenes
  },
  data() {
    return {
      now: null,
      weather_info: {},
      traffice_info: {
        head_count: {}
      },
      member_info: {},
      mp_member_info: {
        yesterdayData: {}
      },
      opinion_list: [],
      storeCount: 0,
    };
  },
  methods: {
    updateNow() {
      this.now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    },
    getMountedData() {
      getWeather().then((res) => {
        this.weather_info = res.data.data
      });
      getMPMemberInfo().then((res) => {
        let yesterday = moment().subtract(1, 'days');
        if (res.data.data.usersummary_list){
          this.mp_member_info["yesterdayData"] = {new_user: 0}
          const yesterdayData = res.data.data.usersummary_list.filter((a) => {
            return a.ref_date == yesterday
          })
          yesterdayData.forEach((a) => {
            this.mp_member_info["yesterdayData"].new_user += a.new_user
          })
        }
      })
    },
    getRealTimeData(){
      getHeatingPower().then((res) => {
        this.traffice_info = res.data.data;
      })
      getMemberInfo().then((res) => {
        this.member_info = res.data.data;
      });
      getUnhandledComplaintList().then((res) => {
        this.opinion_list = res.data.data;
      })
    },
    changeStoreCount(count){
      this.storeCount = count
    }
  },
  mounted() {
    this.getMountedData()
    this.getRealTimeData()
    setInterval(this.getRealTimeData, 6000)
    setInterval(this.updateNow, 1000)
  }
};
</script>

<style scoped>
a{
  text-decoration:none;
  color: #fff;
}
h2 {
  text-align: center;
}
#air-quality {
  width: 750px;
  height: 100px;
  /* color: #1675cf; */
  padding: 20px;
  display: flex;
  flex-direction: row;
  /* border: 1px solid white; */
}

#air-quality > .flex-item {
  flex: auto;
}

.today_temp{
  font-size: 35px;
  font-weight: bold;
  display: block;
}

.today_weather{
  display: block;
  font-size: 25px;
}
.today_pm25{
  font-size: 25px;  
}

.date{
  font-size: 35px;
  font-weight: bold;
  float: right;
  text-align: center;
}

#hangyezhanbi{
  width: 450px;
  height: 340px;
  font-size: 20px;
  /* border: 1px solid white; */
  /* margin-top: 20px; */
}

#shangpujingyinfenxi{
  width: 390px;
  height: 990px;
  /* margin-top: 20px; */
  /* border: 1px solid white; */
}

#realtime-orders{
  height: 340px;
  font-size: 16px;
  /* margin: 20px 20px auto 0; */
}

#ranking-list{
  width: 390px;
  height: 380px;
  font-size: 16px;
  padding-top: 30px;
  margin: 20px 20px auto 0;
  /* border: 1px solid white; */
}

#ranking-list li {
  margin-bottom: 5px;
}

#ranking-list span.score{
  float: right;
}

.store_top_1{
  color: red;
  font-size: 18px;
}

.store_top_2{
  color: #fac858;
  font-size: 17px;
}

#shangchenwuliu{
  width: 390px;
  height: 400px;
  font-size: 20px;
  /* border: 1px solid white; */
  /* margin-bottom: 250px; */
}

#orders-title {
  margin-top: -20px;
}

#complaint-list{
  width: 800px;
  height: 550px;
  font-size: 20px;
  padding: 20px;
  /* border: 1px solid white; */
  background-color: #020128;
}

#complaint-list table{
  margin-top: 20px;
}

#complaint-list td{
  padding-bottom: 10px;
}

#complaint-list .date{
  font-size: 16px;
}

#complaint-list span.score{
  float: right;
}

#traffic{
  width: 800px;
  display: flex;
  flex-direction: row;
  /* border: 1px solid white; */
}
#traffic-realtime {
  margin: 0 10px auto 10px;
}

#traffic-realtime {
  width: 400px;
  height: 400px;
}

#traffic-stat {
  padding-left: 20px;
  width: 400px;
  height: 400px;
}

.current-traffice{
  color: red;
  margin: 0 10px 0 10px;
}

p.title{
  font-size: 28px;
  font-weight: bold;
  /* text-align: center; */
  margin-top: 10px;
  background: url("../assets/point.png") no-repeat;
  background-size: 30px;
  padding-left: 35px;
  color: #fff;
}

#accident{
  width: 390px;
  height: 380px;
  font-size: 20px;
  /* border: 1px solid white; */
  margin-top: 20px;
}

#members ul{
  margin-top: 30px;
}

#members li{
  font-size: 20px;
  margin-bottom: 18px;
  font-weight: bold;
  padding-left: 10px;
}

#members{
  /* margin-top: 20px; */
  /* border: 1px solid white; */
  padding-left: 20px;
  height: 420px;
  width: 380px;
}

#member-layers{
  width: 380px;
}

#members .member-count{
  float: right;
}

#coupons{
  width: 390px;
  height: 340px;
  font-size: 20px;
  /* border: 1px solid white; */
  /* margin-top: px; */
  margin-left: 20px;
  margin-top: -30px;
}

#coupons .title {
  margin-bottom: 20px;
}

#complaints {
  width: 390px;
  /* height: 320px; */
  font-size: 20px;
  /* border: 1px solid white; */
  margin-top: 20px;
  margin-left: 20px;
}

#maps{
  width: 800px;
  height: 755px;
  /* background: url("../assets/bg-imgage.jpg") repeat-x; */
}

#scenes{
  width: 350px;
  /* margin-left: -300px; */
}

.flex {
  align-items: start;
}
.home{
  display: flex;
}
.cloumn-left {
  flex-direction: column;
  background-color: #020128;
  padding: 30px 10px 30px 30px;
}

.column-left-bottom {
  display: flex;
  background-color: #020128;
}

.column-left-stores, .column-left-turnover{
  flex-direction: column;
  background-color: #020128;
}

.cloumn-middle {
  background-color: transparent;
}
.cloumn-right{
  width: 100%;
  padding-left: 28px;
  background-color: #020128;
}

</style>
