<template>
  <v-chart class="chart" :option="option" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { getOrders } from "@/api/api.js";
let moment = require("moment") // 引入

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {},
  data() {
    return {
      colors: [ "#e63e3e", "#fd7e26", "#fbc737", "#455500", "#455500"],
      timer: 0,
      option: {
        grid: {
          x: 40,
          y: 20,
          x2: 40,
          y2: 20,
        },
        xAxis: { show: true, splitLine: {show: false}},
        yAxis: {
          inverse: true,
          show: false,
          data: ["星巴克", "绍东家", "大牌楼", "咸享酒店", "台门人家", "1", "2"],
          axisTick: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            data: [1, 5, 4, 2, 10],
            type: "bar",
            barGap: 0,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "insideBottom",
                  distance: 15,
                  align: "left",
                  verticalAlign: "top",
                  formatter: '{b} / {c}元',
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#fff"
                },
                color: "#70ad46",
              },
              borderRadius: [15, 15, 15, 15],
            },
          },
        ],
      },
    };
  },
  methods: {
    getData() {
      const start = moment().clone().startOf('month').format('YYYY-MM-DD 00:00:00');
      const end   = moment().clone().endOf('month').format('YYYY-MM-DD 23:59:59');
      getOrders(start, end).then((res) => {
        // const data = [{group_name: "test1", sum_price: "100"}, {group_name: "test2", sum_price: "80"}, {group_name: "test3", sum_price: "50"}]

        const data = {}
        res.data.data.forEach((o) => {
          o.list.forEach((group) => {
            if(data[group.group_name]){
              data[group.group_name] += group["sum_price"]
            } else {
              data[group.group_name] = group["sum_price"]
            }
          })
        });
        const sortedData = Object.keys(data).sort(function(a,b){return data[b]-data[a]})
        this.option.series[0].data = sortedData.map((name, i) => {
          return {
            name: name,
            value: data[name].toFixed(2),
            itemStyle: {
              color: this.colors[i] || this.colors[this.colors.length - 1]
            }
          }
        })
      });
    },
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.getData, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>
<style scoped>
.chart{
  height: 250px;
  width: 600px;
  margin-left: 10px;
}
</style>
