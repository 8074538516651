<template>
  <v-chart class="chart" :option="option" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

import { getHeatingPowerDaily } from "@/api/api.js";

export default {
  components: {},
  data() {
    return {
      colors: [ "#e63e3e", "#fd7e26", "#fdc83e", "#58ff80", "#13aaf5"],
      timer: 0,
      option: {
        height: 280,
        grid: {
          x: 40,
          y: 20,
          x2: 40,
          y2: 20,
        },
        xAxis: { show: false, interval: 50},
        yAxis: {
          show: true,
          data: [],
          axisTick: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            data: [1, 5, 4, 2, 10],
            type: "bar",
            // barGap: 0,
            barCategoryGap: '40%',
            showBackground: true,
            backgroundStyle: {
              color: "rgba(132, 178, 197, 0.3)",
              borderRadius: [15, 15, 15, 15],
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "insideBottom",
                  distance: 15,
                  align: "left",
                  verticalAlign: "middle",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#fff"
                },
                color: "#90cc75",
              },
              borderRadius: [15, 15, 15, 15],
            },
          },
        ],
      },
    };
  },
  methods: {
    getData() {
      getHeatingPowerDaily().then((res) => {
        let result = res.data.data.map((item) => {
          return {name: item.data_time.split("-").slice(1,3).join('-'), num: item.head_count.data_total_num}
        });

        const sortedResult = [...result].sort(function (a, b) {
          return b.num - a.num;
        });

        this.option.series[0].data = result.map((o) => {
          let i = sortedResult.findIndex((_d) => { return _d.name == o.name })
          return {
            value: o.num,
            itemStyle: {
              color: this.colors[i] || this.colors[this.colors.length - 1]
            }
          }
        });
        this.option.yAxis.data = result.map((o) => {
          return o.name
        });
      });
    },
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.getData, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>
<style scoped></style>
