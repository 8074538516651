<template>
  <v-chart class="chart" :option="option"  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { getOrders } from "@/api/api.js";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
// var data = [15, 40, 20, 52, 27]
export default {
  components: {},
  props: ["data"],
  data() {
    return {
      colors: [ "#e63e3e", "#fd7e26", "#fdc83e", "#58ff80", "#5370c6"],
      option: {
        grid: {
          x: 30,
          y: 20,
          x2: 0,
          y2: 30,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
                color: '#999'
            }
          }
        },
        xAxis: {
          show: false,
          data: [],
          axisLabel: {
            color: "#a3fffe",
            fontWeight: "bold"
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          show: true,
          type: "value",
          axisTick: false,
          splitLine: {
            show: false
          },
          interval: 10,
          axisLabel: {
            color: "#a3fffe",
          },
        },
        series: [
           {
            name: "订单量",
            type: "bar",
            data: [],
            barGap: "-100%",
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top'
                }
              },
            }
          },
           {
            name: "订单量",
            type: "bar",
            barCategoryGap: '50%',
            data: [89, 68, 32, 41, 21, 43, 54],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: '{b}',
                  rotate: 90,
                  fontSize: 14
                }
              },
            }
          }
        ],
      },
    };
  },
  methods: {
    getData() {
      getOrders().then((res) => {
        const data = res.data.data[0].list.map((s, i) => {
          return {
            name: s.group_name,
            value: s.sum_count,
            itemStyle: {
              color: this.colors[i] || this.colors[this.colors.length - 1]
            }
          }
        }).slice(0,10)
        this.option.series[0].data = data
        this.option.series[1].data = data
        return
      });
    }
  },
  mounted() {
    this.getData();
    this.timer = setInterval(this.getData, 6000)
  },
};
</script>
<style scoped>
.chart{
  height: 300px;
}
</style>