<template>
  <v-chart class="chart" :option="option"  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { getMPMemberInfo } from "@/api/api.js";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  components: {},
  props: ["data"],
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
                color: '#999'
            }
          }
        },
        xAxis: {
          show: true,
          data: [],
          axisLabel: {
            color: "#a3fffe",
            fontWeight: "bold"
          }
        },
        yAxis: [
          {
            name: '合计',
            show: true,
            axisTick: false,
            position: 'right',
            splitLine: {
              show: false
            },
            axisLabel: {
              color: "#fff",
            },
            nameTextStyle: {
              color: "#fff",
            }
          },
          {
            name: '增长数',
            position: 'left',
            axisLabel: {
              formatter: '{value}',
              color: "#fff",
            },
            nameTextStyle: {
              color: "#fff",
            },
            splitLine: {
              show: false
            },
          }
        ],
        series: [
           {
            name: "公众号用户关注数",
            type: "bar",
            barCategoryGap: '50%',
            data: [89, 68, 32, 41, 21, 43, 54],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  rotate: 90,
                  fontSize: 16
                }
              },
            }
          },
          {
            name: '增长人数',
            type: 'line',
            yAxisIndex: 1,
            data: []
          }
        ],
      },
    };
  },
  methods: {
    getData() {
      getMPMemberInfo().then((res) => {
        let datas = res.data.data.usercumulate_list.map((list) => {
          return list.cumulate_user
        })
        this.option.series[0].data = datas.map((num, i) => {
          let color
          if (i == 0){
            color = "#5370c6"
          } else if( (datas[i] - datas[i-1]) < 0 ){
            color = "#13f0f5"
          } else {
            color = "#5370c6"
          }
          return {
            value: num,
            itemStyle: {
              color: color
            }
          }
        })
        this.option.series[1].data = [
          0,
          datas[1] - datas[0],
          datas[2] - datas[1],
          datas[3] - datas[2],
          datas[4] - datas[3],
          datas[5] - datas[4],
          datas[6] - datas[5],
        ]
        this.option.xAxis.data = res.data.data.usercumulate_list.map((list) => {
          return list.ref_date.split("-").slice(1,3).join('-')
        })
      });
    }
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
.chart{
  height: 380px;
}
</style>