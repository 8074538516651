<template>
  <v-chart class="chart" :option="option"  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { getCouponStatDaily } from "@/api/api.js";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
// var data = [15, 40, 20, 52, 27]
export default {
  components: {},
  props: ["data"],
  data() {
    return {
      colors: [ "#e63e3e", "#fd7e26", "#fdc83e", "#58ff80", "#13f0f5"],
      option: {
        // title: {
        //   text: '每日优惠券领用情况',
        //   left: 'center',
        //   textStyle: {
        //     color: "#a3fffe",
        //     fontWeight: "bold"
        //   }
        // },
        grid: {
          x: 30,
          y: 20,
          x2: 0,
          y2: 30,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
                color: '#999'
            }
          }
        },
        xAxis: {
          data: ["07-01", "07-02", "07-03", "07-04", "07-05","07-06", "07-07"],
          axisLabel: {
            color: "#a3fffe",
            fontWeight: "bold"
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          x: 'right',
          y: 'top',
          data: ['每日领取数量', '每日使用数量'],
          textStyle: {
            color: "#fff",
            fontWeight: 'bold',
          }
        },
        yAxis: {
          show: true,
          type: "value",
          axisTick: false,
          splitLine: {
            show: false
          },
          axisLabel: {
            color: "#a3fffe",
          },
        },
        series: [
           {
            name: "每日领取数量",
            type: "bar",
            barGap: "-100%",
            data: [89, 68, 32, 41, 21, 43, 54],
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              },
            }
          },
          {
            name: "每日使用数量",
            data: [20, 40, 2, 2, 2, 1, 1],
            type: "bar",
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              },
            }
          },
         
        ],
      },
    };
  },
  methods: {
    getData() {
      getCouponStatDaily().then((res) => {
        this.option.yAxis["max"] = Math.max.apply(Math, res.data.data.map((o) => { return o.receive_number })) + 1;
        this.option.xAxis.data = res.data.data.map((o) => {
          return o.date.split("-").slice(1,3).join('-')
        });
        this.option.series[0].data = res.data.data.map((o) => {
          return o.receive_number
        });
        let result = res.data.data.map((o) => {
          return {name: o.date.split("-").slice(1,3).join('-'), value: o.verify_number }
        });
        const sortedResult = [...result].sort(function (a, b) {
          return b.value - a.value;
        });
        this.option.series[1].data = result.map((o) => {
          let i = sortedResult.findIndex((_d) => { return _d.name == o.name })
          return {
            name: o.name,
            value: o.value,
            itemStyle: {
              color: this.colors[i] || this.colors[this.colors.length - 1]
            }
          }
        });
        return
      });
    }
  },
  mounted() {
    this.getData();
    this.timer = setInterval(this.getData, 6000)
  },
};
</script>

<style scoped>
.chart{
  height: 260px;
}
</style>
