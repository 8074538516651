<template>
  <section class="store-info">
    <div class="store-list">
      <table>
        <thead>
          <tr>
            <th>景区</th>
            <th>当前参观人数</th>
            <th>承载量</th>
          </tr>
        </thead>
        <tbody class="store-list-wrap" :class="{ 'animate-up': animateUp }">
          <tr v-for="(point, i) in points" :key="i">
            <td>
              <div class="bg2">
                {{ point.name.split("-")[0] }}
              </div>
            </td>
            <td>
              <div class="bg2">{{ (point.inNum > 0) ? point.inNum : 0 }}</div>
            </td>
            <td>
              <div class="bg2">
                {{ point.limitNum }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import { getScenePassengers } from "@/api/api.js";

export default {
  data() {
    return {
      animateUp: false,
      timer: null,
      points: [],
    };
  },
  methods: {
    scrollAnimate() {
    },
    getData() {
      getScenePassengers().then((res) => {
        this.points = res.data.data.filter(point => {
          if(["三味书屋-免费", "鲁迅故居-免费", "鲁迅纪念馆", "鲁迅祖居-免费"].includes(point.name)) {
            return true
          } else {
            return false
          }
        });
      });
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.scrollAnimate, 3000);
  },
};
</script>
<style scoped>
.store-info {
  padding-top: 30px;
}
.store-info table {width:100%;color:#fff;margin-top:10px;}
.store-info table .bg {
  background: #14282e;
  padding: 5px;
  margin-bottom: 3px;
}

.store-info table .bg2 {
  background: rgba(12, 28, 34, 0.7);
  padding: 8px;
  margin-bottom: 3px;
}


.store-info table td:first-child .bg2,
.store-info table th:first-child .bg {
  border-radius: 10px 0 0 10px;
}

.store-info table td:last-child .bg2,
.store-info table th:last-child .bg {
  border-radius: 0 10px 10px 0;height: 36px;
}

.store-info table td,
.store-info table thead th {
  padding: 0;
  text-align:center;
  padding-bottom: 10px;
}


.store-list {
  height: 380px;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  color: '#fff';
}
.store-list thead {
  position: relative;
  z-index: 1;
}
.store-list-wrap {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.store-list .animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateY(-40px);
}
</style>
