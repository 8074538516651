<template>
  <v-chart class="chart" :option="option" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";

import { getComplaintInfo } from "@/api/api.js";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  data() {
    return {
      timer: 0,
      option: {
        legend: {
          data: ['投诉', '建议', '投诉处理率', '建议处理率'],
          textStyle:{
            color: "#fff",
            fontWeight: "bold"
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: { backgroundColor: "#6a7985" },
          },
        },
        xAxis: {
          show: true,
          type: 'category',
          data: ["07-01", "07-02", "07-03", "07-04", "07-05","07-06", "07-07"],
           axisLabel: {
            color: "#a3fffe",
            fontWeight: "bold"
          },
        },
        yAxis: {
          show: true,
          splitLine: {
            show: false
          },
          axisLabel: {
            color: "#a3fffe",
          },
        },
        series: [
          {
            name: '投诉',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 21000]
          },
          {
            name: '建议',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 21000]
          },
          {
            name: '投诉处理率',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 21000]
          },
          {
            name: '建议处理率',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 21000]
          }
        ]
      }
    }
  },
  methods: {
    getData() {
      getComplaintInfo().then((res) => {
        this.option.xAxis.data = res.data.data.map((o) => {
          return o.date.split("-").slice(1,3).join('-')
        })
        this.option.series[0].data = res.data.data.map((o) => {
          return o.complaint_num
        })
        this.option.series[1].data = res.data.data.map((o) => {
          return o.advise_num
        })
        this.option.series[2].data = res.data.data.map((o) => {
          return o.complaint_processed_num
        })
        this.option.series[3].data = res.data.data.map((o) => {
          return o.advise_processed_num
        })
      });
    },
  },
  mounted() {
    this.getData()
    setInterval(this.getData, 6000)
  }
}
</script>
<style scoped>
.chart{
  margin-top: 20px;
  height: 360px;
}
</style>